.container{
}

.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-bottom: 22px;
    margin-top: 0px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span{
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;

}

.voices{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


}


.firstVoices div:first-child, .secondVoices div:first-child{
    margin-left: 0;
}


.firstVoices, .secondVoices{
    display: flex;
    margin-top: 46px;
}
@media (max-width: 1024px) {
    .voices{
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 18px;
    }
    .firstVoices, .secondVoices{
        flex-direction: column;
        margin-top: 0;
    }
    .secondVoices{
        margin-left: 50px;
    }
}

@media (max-width: 768px) {
    .h2{
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }
    .container p{
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }
    .useCasesTitle{
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span{
        font-size: 22px;
        line-height: 25px;
    }

    .voices{
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

    }

    .firstVoices, .secondVoices{
        flex-direction: column;
        margin-top: 0;
        width: 50%;
    }

    .secondVoices{
        margin-left: 24px;
    }
}

