.container{
    display: flex;
    flex-direction: row;
    margin-top: 80px;
    margin-bottom: 80px;
}

.dot{
    margin-left: 24px;
}

.star{
    width: 52px;
    animation: moving 6s infinite linear;
}
@keyframes moving {
    100% {transform: rotate(360deg);}
}

@-webkit-keyframes moving {
    100% {transform: rotate(360deg);}
}

@media (max-width: 768px) {
    .container{
        display: flex;
        justify-content: center;
        margin-top: 45px;
        margin-bottom: 45px;
    }
}
