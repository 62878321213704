.container{
    padding: 12px 25px 10px 16px;
    border-radius: 16px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 100%);
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;

}

.container img{
    max-height: 38px;
    width: 40px;
}

.text{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 112%;
    color: #000000;
    margin-left: 12px;
    display: flex;
    align-items: center;
    transition: .2s;

}

.container:active{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 100%);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    transition: .2s;
}

.container:hover{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
    transition: .2s;
}

@media (max-width: 768px) {
    .container{
        padding: 9px 16px;
    }

    .text{
        font-size: 22px;
        line-height: 27px;
    }

    .container img{
        width: 20px;
    }
}
