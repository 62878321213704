.container{
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.itemPeople{
    position: relative;
    width: 75px;
    height: 75px;
}

.imagePeople{
    width: 64px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.buttonPlay{
    position: absolute;
    bottom: 6px;
    right: -8px;
    border-radius: 100px;
    width: 38px;
    height: 38px;
}

.buttonPlay button{
    border: none;
    background-color: inherit;
    border-radius: 100px;
    width: 32px;
    height: 32px;
    color: #FFFFFF;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
}

.buttonPlay button img{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 32px;
    height: 32px;
}

.buttonPlayWithoutImage button{
    border: none;
    background-color: inherit;
    border-radius: 100px;
    width: 64px;
    height: 64px;
    cursor: pointer;
    padding: 0;
}

.buttonPlayWithoutImage{
    display: flex;
    justify-content: center;
}

.buttonPlayWithoutImage button img{
    width: 64px;
    height: 64px;
}



.text{
    display: flex;
    flex-direction: column;
    padding-left: 12px;
}

.text span:first-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
}

.text span:last-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

@media (max-width: 1024px) {
    .container{
        align-items: center;
        margin-top: 35px;
        margin-left: 0;
    }
    .imagePeople{
        position: relative;
    }
    .itemPeople{
        width: 64px;
        height: 64px;
    }

    .imagePeople{
        width: 64px;
        position: relative;
    }

    .buttonPlay{
        width: 38px;
        height: 38px;
        bottom: -3px;
        right: -7px;
    }

    .buttonPlay button{
        width: 32px;
        height: 32px;

    }

    .buttonPlay button img{

        width: 32px;
        height: 32px;
    }


}

@media (max-width: 768px) {
    .container{
        align-items: center;
        margin-top: 14px;

    }

    .itemPeople{
        width: 45px;
        height: 45px;
    }

    .imagePeople{
        width: 45px;
        position: relative;
    }

    .buttonPlay{
        width: 28px;
        height: 28px;
        bottom: -3px;
        right: -5px;
    }

    .buttonPlay button{
        width: 22px;
        height: 22px;

    }

    .buttonPlay button img{

        width: 22px;
        height: 22px;
    }

    .buttonPlayWithoutImage{
        width: 45px;
        height: 45px;
        position: relative;

    }
    .buttonPlayWithoutImage button{
        position: absolute;
        width: 45px;
        height: 45px;
    }

    .buttonPlayWithoutImage button img{
        width: 45px;
        height: 45px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .text{
        display: flex;
        flex-direction: column;
        padding-left: 8px;
        margin-bottom: -2px;
    }

    .text span:first-child{
        font-size: 22px;
        line-height: 22px;
    }

    .text span:last-child{
        font-size: 22px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.5);
    }
}
