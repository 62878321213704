.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 20px;
}

.contacts{
    display: flex;
    flex-direction: row;
    margin-top: 32px;
}

.contacts a{
    padding: 19px 30px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    cursor: pointer;
    transition: .5s
}

.contacts a:hover{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
    transition: .5s
}

.contacts a:active{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    transition: .5s
}

@media (max-width: 768px) {
    .container{
        justify-content: center;
    }

    .h2 {
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .contacts{
        margin-top: 16px;
        justify-content: center;
    }

    .contacts a{
        padding: 11px 20px;
        font-size: 22px;
        line-height: 25px;
    }
}

