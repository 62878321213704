
.container{
    margin-top: 80px;
}

.imageGroup{
    margin-left: 24px;
    width: 550px;
    height: 142px;
}

.imageGroup img{
    width: 550px;
    opacity: 0.7;
}

.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 30px;
}

.whiteBlocksAnchor{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .container{
        margin-top: 33px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .imageGroup{
        margin: 0 auto;
        width: 295px;
        height: 75px;
    }

    .imageGroup img{
        width: 295px;
    }

    .h2{
        font-size: 50px;
        line-height: 49px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .whiteBlocksAnchor{
        justify-content: center;
    }
}

