@font-face {
    font-family: "TT Commons";
    font-display: block;
    font-weight: 700;
    font-style: normal;
    src: local("TT Commons Bold"), local("TTCommons-Bold"),url('fonts/TT Commons Bold.otf');
}

@font-face {
    font-family: "TT Commons";
    font-display: block;
    font-weight: 600;
    font-style: normal;
    src: local("TT Commons Demibold"), local("TTCommons-Demibold"),url('fonts/TT Commons DemiBold.otf');
}

@font-face {
    font-family: "TT Commons";
    font-weight: 500;
    font-style: normal;
    font-display: block;
    src: local("TT Commons Medium"), local("TTCommons-Medium"),url('fonts/TT Commons Medium.otf');
}

@font-face {
    font-family: "TT Commons";
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: local("TT Commons Regular"), local("TTCommons-Regular"), url("fonts/TT Commons Regular.otf");
}

@font-face {
    font-family: "TT Commons";
    font-weight: 100;
    font-style: normal;
    font-display: block;
    src: local("TT Commons Thin"), local("TTCommons-Thin"),url('fonts/TT Commons Thin.otf');
}



a{
    text-decoration: none;
    color: inherit;
}

b{
    font-family: 'TT Commons', sans-serif;
    font-weight: 600 ;
}

picture, img{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.website{
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 80px;
}

.container {
    width: 100%;
    padding-bottom: 120px;
}


.animation-wrapper {
    margin-left: 5%;
    width: 90%;
    padding-bottom: 26%;
    opacity: 0.6;
    position: absolute;
    z-index: -1;
    --blur: 100px;
    --spread: 5px;
    /* не изменять */
    --grad: transparent calc(var(--spread)),
    #fff calc(var(--spread) + var(--blur)),
    #fff calc(100% - (var(--spread) + var(--blur))),
    transparent calc(100% - var(--spread));
    /* не изменять */
    mask-image:
            linear-gradient(to right, var(--grad)),
            linear-gradient(to bottom, var(--grad));
    mask-composite: intersect;

}

.stagger-visualizer {

    position: absolute;
    width: 1100px;
    height: 550px;
    transform-origin: left top;

}

.stagger-visualizer .dots-wrapper {
    transform: translateZ(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #FFD1D1;
    background-color:rgba(255, 209, 209, 0.002);
}

.stagger-visualizer .dot {
    position: relative;
    z-index: 1;
    width: 23px;
    height: 23px;
    margin: 16px;
    border-radius: 50%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}



@media (max-width: 1024px) {
    .website{
        padding: 0 80px;
    }
}

@media (max-width: 768px) {
    .website{
        padding: 15px 24px;
    }
    .animation-wrapper{
        width: 100% ;
        margin-left: 0 ;
        opacity: 0.8 ;
        height: 600px;
        --blur: 50px;
        --spread: 5px;
        /* не изменять */
        --grad: transparent calc(var(--spread)),
        #fff calc(var(--spread) + var(--blur)),
        #fff calc(100% - (var(--spread) + var(--blur))),
        transparent calc(100% - var(--spread));
        /* не изменять */
        mask-image:
                linear-gradient(to right, var(--grad)),
                linear-gradient(to bottom, var(--grad)) !important;
        mask-composite: intersect;
    }
    .stagger-visualizer{
        height: 400px ;

    }
}


