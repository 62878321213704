

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
.spinner{
    animation-name: spin;
    animation-duration: 1s;
    
    animation-iteration-count: infinite;
  }

.video-dub-header{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 700;


    color: #000000;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-bottom: 22px;
    margin-top: 0px;
}

.video-dub-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.video-dub-horizontal{
    display: grid;
    grid-template-columns: 6fr 4fr;
    gap: 30px;
}

.video-dub-player{
    width: 100%;
    height: 560px;
    box-sizing: border-box;
    position: relative;
        border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    overflow: hidden;
    background: transparent;
}
.video-dub-player video{
    width: 100%;
    height: 560px;
    object-fit: cover;
}
.video-dub-overlay{
    height: 480px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-dub-btn{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: rgba(34, 34, 34, 0.5);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-dub-btn img{
    padding-left: 8px;
}

.video-dub-input-container{
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 290px;
    box-sizing: border-box;
}

.video-dub-column{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.video-dub-textarea{
    width: 100%;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 38px;
    overflow: hidden;
    /* or 112% */

    outline: none;
    background: transparent;
    resize: none;
    border: none;
    height: 188px;
    color: #000000;
    }

    .video-dub-input-container:focus-within{
        background: #FFFFFF;
        border: 2px solid #000000;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        box-sizing: border-box;
        margin-left: -1px;
        margin-top: -1px;
        margin-bottom: 1px;
      }



.d-flex{
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    font-family: 'TT Commons';
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 30px;
/* identical to box height */


color: rgba(0, 0, 0, 0.5);
}

.video-dub-select{
    /* background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1); */
    /* border-radius: 20px; */
    width: 100%;
    /* height: 60px; */
    height: 140px;
}

.video-dub-button{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 60px;
    cursor: pointer;
    font-family: 'TT Commons';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 25px;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;

color: #000000;

}


.video-dub-button:disabled{
    opacity: 0.3;
  }

  .disabled{
    opacity: 0.3;
  }
  

.video-dub-button:hover{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
  transition: .5s
}

.dub-option{
    width: 106px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 90px;
    cursor: pointer;
}

.dub-selected{

    background-image: url('./selected-bg.svg');
    background-position: center center;
    background-repeat: no-repeat;
    transition: 0.1s ease-in-out;
    box-sizing: border-box;
}

.dub-option-image{
    width: 110px;
    height: 110px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;background-position: center center;
    background-repeat: no-repeat;
    transition: 0.1s ease-in-out;
}

.dub-option-image img{
    width: 90px;
    height: 90px;
    border-radius: 90px;
    object-fit: cover;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
border: 1px solid rgba(0, 0, 0, 0.2);
box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);

}

.video-dub-select{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.dub-name{
    font-family: 'TT Commons';
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 30px;

color: rgba(0, 0, 0, 0.5);
}

.dub-name-selected{

color: #000000;
}

.appearance{
    font-family: 'TT Commons';
font-style: normal;
font-weight: 400;
font-size: 26px;
line-height: 30px;
/* identical to box height */

text-align: center;

color: rgba(0, 0, 0, 0.5);
margin-bottom: -20px;
}


.video-overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left :0;
  }


.video-play{
    width: 100px;
    height: 100px;
    background: rgba(34, 34, 34, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 6px;
    box-sizing: border-box;
  }

  .video-play:hover{
  
    background: rgba(34, 34, 34, 0.75);
  }

  .pd3{
    padding-top: 6px;
  }

  .header{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-bottom: 10px;
  }

  .useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  .use{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 48px;
  }

  .use span{
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
  }


  @media screen and (max-width: 786px) {
    h2.header{
      font-size: 48px;
      line-height: 52px;
      text-align: center;
    }
    .video-dub-horizontal{
        grid-template-columns: 1fr;
    }
    .use{
        justify-content: center;
    }

    .use span{
        font-size: 22px;
        line-height: 25px;
    }
  }


  @media screen and (max-width: 380px) {
    .d-flex{
      font-size: 20px;
      white-space: nowrap;
    }
  }