.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 22px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span {
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.desktopVideos{

}

.voicesVideo{
    display: flex;
    flex-direction: row;
    margin-top: 80px;
}

.imageTitleVideoDesktop{
    position: absolute;
    margin-top: -50px;
}

.imageTitleVideoDesktop img{
    width: 90px;
}

.videoContainerDesktop{
    margin-right: 14px;
}

.videoDesktop{
    border-radius: 16px;
    width: 94%;
    height: auto;
    max-height: 100%;
    cursor: pointer;
}

.voicesVideo div:not(:first-child) video{
    clip-path: url('#clipping');
}

.svgMask{
    width: 90%;
    height: auto;
    max-height: 100%;
    position: fixed;
}


.mySwiper{
    margin-top: 40px;
    width: 100%;

}

.videoContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 45px;
    z-index: 1000;
}

.borderVideo{
    width: 274px;
    height: 423px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 12px;
    position: relative;
    cursor: pointer;
}


.video{
    position: relative;
    left: 0;
    top: 0;
    opacity: 1;
    transition-duration: .2s;
    width: 316px;
    height: 423px;
    cursor: pointer;
}

.mySwiper div div:not(:first-child) div div video{
    clip-path: url('#clipping');
}

.titleVideo{
    background-color: inherit;
}

.textTitleVideo{
    position: absolute;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
    margin-top: -45px;
}

.imageTitleVideo {
    position: absolute;
    z-index: 1000;
    margin-top: -38px;
    margin-left: -6px;
    width: 96px;
    height: 96px;
    border-radius: 100px;
    padding-left: 6px;
}

.imageTitleVideo img{
    width: 90px;
}

@media (max-width: 1024px) {

    .textTitleVideo{
        white-space: nowrap;
    }
}

@media (max-width: 768px) {
    .h2 {
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .container p {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }

    .useCasesTitle {
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span {
        font-size: 22px;
        line-height: 25px;
    }

    .textTitleVideo{
        font-size: 21px;
        line-height: 22px;
        margin-top: -15px;
    }

    .imageTitleVideo{
        width: 70px;
        height: 70px;
        margin-top: -26px;
        margin-left: -9px;
    }

    .borderVideo{
        width: 168px;
        height: 275px;
        border-radius: 16px;
    }

    .video{
        width: 204px;
        height: 275px;
        border-radius: 16px;
        cursor: pointer;
    }

    .mySwiper div div:not(:first-child) div .borderVideo{
        clip-path: url('#clipping768px');
    }

    .videoContainer{
        padding-top: 30px;
    }

    .imageTitleVideo img{
        width: 64px;
    }

    .mySwiper{
        margin-top: 20px;
        left: -24px;

        width: calc(100% + 48px);
    }

    .mySwiper div div:not(:first-child) div div video{
        clip-path: none ;
    }

    .overlayVideo{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        z-index: 2000;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        cursor: pointer;
    }

    .overlayVideo img{
        border-radius: 100px;
        background-color: rgba(0,0,0,0.6);
        width: 70px;
    }
}
