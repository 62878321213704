
.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 22px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;

}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span {
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.videos{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.videoContainer{
    display: flex;
    flex-direction: column;


}

.video{
    width: 274px;
    height: auto;
    max-height: 100%;
    opacity: 1;
    transition-duration: .2s;
    border-radius: 16px;
    cursor: pointer;

}

.borderVideo{
    position: relative;
    clip-path: url('#clippingLip');
    border-radius: 16px;
    margin-top: 12px;
    cursor: pointer;
}

.typeVideo{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
}


.choiceVideo{
    display: flex;
    flex-direction: column;
    background-color: inherit;
}

.choiceVideo button{
    border-radius: 40px;
    padding: 10px 26px 10px 10px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: inherit;
    margin-top: 4px;
}

.choiceVideo button span{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
    display: flex;
    align-items: center;
}

.choiceVideo button img{
    margin-right: 16px;
}

.choiceVideo button:hover{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.1) 100%) ;

}

.activeButton{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.132) 0%, rgba(0, 0, 0, 0.22) 100%) !important;
}

.activeButton:hover{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.132) 0%, rgba(0, 0, 0, 0.22) 100%) !important;
}

.overlayVideo{
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 2000;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    cursor: pointer;
}

.overlayVideo img{
    border-radius: 100px;
    background-color: rgba(0,0,0,0.6);
    width: 70px;
}

@media (max-width: 1100px) {
    .arrow{
        display: none;
    }

    .video{

    }
}
@media (max-width: 1024px) {
    .video{
        width: 100%;

    }
    .typeVideo{
        white-space: pre;
        overflow:hidden;
        text-overflow: ellipsis;
        width: 210px;
    }

    .choiceVideo{
        margin-left: 10px;
        margin-right: 10px;
    }

}
@media (max-width: 900px) {
    .typeVideo{
        width: 150px;
    }
    .borderVideo{

    }
}

@media (max-width: 768px) {
    .h2 {
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .container p {
        font-size: 22px;
        line-height: 30px;
        text-align: center;
    }

    .useCasesTitle {
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span {
        font-size: 22px;
        line-height: 25px;
    }

    .choiceVideo{
        margin-top: 32px;
        justify-content: center;

    }

    .typeVideo{
        font-size: 22px;
        line-height: 22px;

    }

    .containerBtn{
        margin: 0 auto;
    }

    .choiceVideo button{
        border-radius: 28px;
        align-items: center;

        padding: 8px 18px 8px 8px;
    }

    .choiceVideo button span{
        font-size: 22px;
        line-height: 25px;
    }


    .choiceVideo button img{
        width: 30px;
        margin-right: 8px;
    }



}
