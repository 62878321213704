.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-top: 36px;
    margin-bottom: 22px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span {
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.messages{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    max-width: 650px;
}

.messages div{
    margin-top: 10px;
}

.messages div span{
    display: flex;
    align-items: center;
    padding: 16px 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.messages div span picture{
    margin-left: 7px;
    width: 26px;
    display: flex;
}

.messages div span picture img{
    width: 25px;
    margin-top: -2px;
}

.messages div:first-child span{
    background: #FFFFFF;
    border-radius: 6px 30px 30px 30px;
    float: left;
}

.messages div:nth-child(2) span{
    background: #FFFAD0;
    border-radius: 30px;
    float: right;
}

.messages div:nth-child(3) span{
    background: #FFFAD0;
    border-radius: 30px 30px 6px 30px;
    float: right;
}

.messages div:last-child span{
    background: #FFFFFF;
    border-radius: 6px 30px 30px 30px;
    float: left;
}

@media (max-width: 768px) {
    .h2 {
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .container p {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }

    .useCasesTitle {
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span {
        font-size: 22px;
        line-height: 25px;
    }

    .messages div span{
        font-size: 22px;
        line-height: 22px;
        padding: 13px 18px;
    }

    .messages div:first-child span{
        border-radius: 4px 22px 22px 22px;
    }
}
