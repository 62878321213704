
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .contact-us-btn:hover{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
    transition: .5s
  }
  .contact-us-btn{
  
    padding: 19px 30px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #000000;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    cursor: pointer;
    transition: .5s;
    text-align: center;
    width: min-content;
    white-space: nowrap;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  html {
    scroll-behavior: smooth;
    background: linear-gradient(270deg,#FFE6CF, #FFD1D1);
  }
  
  .tts-header{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 700;
    font-size: 110px;
    margin-bottom: 10px;
    line-height: 104px;
    color: #000000;
  }
  
  .interactive-input-container{
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    width: 100%;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 106px;
  }
  .interactive-input-container:focus-within{
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    box-sizing: border-box;
    margin-left: -1px;
    margin-top: -1px;
    padding-bottom: 2px;
    margin-bottom: 21px;
  }
  
  .interactive-input-label{
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.5);
  }
  
  .interactive-input{
    background: none;
    border: none;
    resize: none;
    width: 100%;
    font-family: 'TT Commons';
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 38px;
    /* identical to box height, or 112% */
    height: 34px;
    box-sizing: border-box;
    color: #000000;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; 
    padding-bottom: 8px;
  }
  
  .dub-wave{
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    width: 5px;
    height: 65px;
  }
  .dub-wave-active{
  
    background: rgba(0, 0, 0, 0.8);
  }
  .dub-waves{
    display: flex;
    gap: 5px;
  }
  
  .dub-result-container{
    display: grid;
    grid-template-columns: 1fr 1fr 65px;
    gap: 36px;
    justify-content: space-between;
    transition: 0.1s ease-in-out;
    margin-top: 55px;
  }
  
  .dub-generate{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;
  height: 106px;
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  /* identical to box height, or 112% */
  cursor: pointer;
  
  text-align: center;
  
  color: #000000;
  }
  
  .dub-generate:disabled{
    opacity: 0.3;
  }
  
  .disabled{
    opacity: 0.3;
  }
  
  .dub-generate:hover{
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
      border: 1px solid rgba(0, 0, 0, 0.3);
      box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
      transition: .5s
  }
  
  .dub-picker-submit-container{
    margin-top: 20px;
    display: flex;
    gap: 20px;
  
  }
  
  .dub-picker{
    width: 35%;
    min-width: 400px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: 106px;
    padding-top: 20px;
    padding-left: 21px;
    padding-right: 20px;
    padding-bottom: 18px;
    display: grid;
    grid-template-columns: 64px 1fr 38px;
    gap: 14px;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .relative{
  
    position: relative;
  }
  
  .dub-picker-speaker{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height */
  
  
  color: rgba(0, 0, 0, 0.5);
  }
  
  .dub-picker-name{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  /* identical to box height, or 112% */
  
  
  color: #000000;
  }
  
  .dub-picker-image{
    width: 64px;
    height: 64px;
    border-radius: 64px;
    background: white;
    border: none;
  }
  
  .play-btn{
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
  }
  
  .dub-result-left{
    gap: 10px;
    display: flex;
  }
  
  .dub-audio-label{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  /* identical to box height, or 112% */
  
  
  color: #000000;
  padding-top: 4px;
  }
  
  .dub-audio-length{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height */
  
  
  color: #000000;
  }
  
  .brand-content-container{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: 50px;
  }
  
  .brand-item{
    display: flex;
    gap: 10px;
  }
  .brand-image{
    width: 40px;
    height: 40px;
    padding: 20px;
  }
  
  .brand-header{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  margin-top: 10px;
  line-height: 38px;
  
  
  color: #000000;
  
  }
  
  .brand-text{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  margin-top: 4px;
  color: #000000;
  }
  
  
  
  .industry-content-container{
    display: flex;
    gap: 50px;
    width: 100%;
  }
  
  
  .industry-item-300{
    width: 30%
  }
  .industry-item-440{
    width: 44%
  }
  
  .industry-text{
    font-family: 'TT Commons';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 38px;
  /* or 112% */
  margin-top: 14px;
  
  color: #000000;
  }
  
  .industry-image{
    width: 80px;
    height: 80px;
  }
  
  .dub-picker-options{
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    background: linear-gradient(180deg, #FFFFFF 100%, rgba(255, 255, 255, 0.4) 100%);
    border-radius: 20px !important;
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0px;
    box-sizing: border-box;
    z-index: 10;
  }
  
  .no-border{
    border: none !important;
    box-shadow: none;
    cursor: pointer;
    border-radius: 0 !important;
  }
  
  .no-border:hover{
    background-color: #FFE6CF;
  }
  
  @media screen and (max-width: 786px) {
    .dub-picker{
      width: 100% !important;
      min-width: 0;
      box-sizing: border-box;
    }
    .interactive-input{
      height: auto;
      font-size: 24px;
    }
    .interactive-input-container{
      height: auto;
      padding-bottom: 12px;
    }
    p{
      text-align: center 
      ;
    }
    h2{
      text-align: center;
    }
    .industry-content-container div{
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  
    .no-border{
      height: auto;
  
      grid-template-columns: 64px 1fr !important;
    }
    .dub-picker-options{
      width: 100% !important;
    }
    
    .dub-picker-name{
      font-size: 24px !important;
      line-height: 28px !important;
      white-space: nowrap;
      margin-top: 6px;
    }
    
    .dub-picker-submit-container{
      flex-direction: column;
    }
    .dub-result-container{
      grid-template-columns: 1fr !important;
    }
  
    .dub-waves{
      display: none;
    }
  
    .tts-header{
      font-size: 48px;
      line-height: 52px;
    }
    .brand-content-container{
      grid-template-columns: 1fr;
    }
    .industry-content-container{
      flex-direction: column;
    }
  
    .industry-text br {
      display: none;
    }
  
    .industry-item-300{
      width: 100%;
    }
  
    .industry-item-440{
      width: 100%;
    }
  
  .contact-us-btn{
    align-self: center;
    justify-self: center;
  }
  }
  .center-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #a_audio{
    display: none;
  }
  
  .toggle-btn{
    width: 64px;
    height: 64px;
  }
  
  .dub-audio-grey{
  
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  margin-top: -4px;
  /* identical to box height */
  
  
  color: rgba(0, 0, 0, 0.5);
  
  
  }
  
  .dub-spinner{
    width: 64px;
    border-radius: 64px;
    background: rgba(0,0,0,0.1);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  
  .dub-spinner img{
    width: 44px;
    height: 44px;
    animation-name: spin;
    animation-duration: 0.75s;
  
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }
  
  .download-audio-dub{
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .download-audio-dub img{
    width: 54px;
  }
  
  .overlay-background{
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(34, 34, 34, 0.6);
  }

  .container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}


.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-bottom: 22px;
    margin-top: 0px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span{
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;

}

.functional{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.inputText{
    display: flex;
    flex-direction: row;
}

.textContainer{
    display: flex;
    flex-direction: column;
    margin-left: 22px;
}


.textContainer span:first-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
}

.textContainer span:last-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.inputText img{
    width: 60px;
}

.outputVoice{
    margin-left: 0;
}


@media (max-width: 1024px) {
    .functional{
        flex-direction: column;
    }
    .outputVoice{
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .h2{
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .container p{
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }

    .useCasesTitle{
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span{
        font-size: 22px;
        line-height: 25px;
    }

    .functional{
        flex-direction: column;
    }

    .outputVoice{
        margin-left: 0;
    }

    .inputText img{
        width: 44px;
    }

    .textContainer span:first-child{
        font-size: 22px;
        line-height: 22px
    }

    .textContainer span:last-child{
        font-size: 22px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.5);
    }
}
