
.h2{
    font-family: 'TT Commons', sans-serif;
    font-weight: 700;
    font-size: 110px;
    line-height: 104px;
    color: #000000;
    margin-bottom: 22px;
    margin-top: 0px;
}

.container p{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    padding-bottom: 16px;
}

.useCasesTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.useCasesTitle img{
    margin-right: 10px;
}

.useCases{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.useCases span{
    margin-right: 20px;
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;

}

.functional{
    display: flex;
    flex-direction: row;
    margin-top: 50px;
}

.inputText{
    display: flex;
    flex-direction: row;
}

.textContainer{
    display: flex;
    flex-direction: column;
    margin-left: 22px;
}


.textContainer span:first-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 34px;
    line-height: 38px;
    color: #000000;
}

.textContainer span:last-child{
    font-family: 'TT Commons', sans-serif;
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
}

.inputText img{
    width: 60px;
}

.outputVoice{
    margin-left: 0;
}


@media (max-width: 1024px) {
    .functional{
        flex-direction: column;
    }
    .outputVoice{
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .h2{
        font-size: 50px;
        line-height: 49px;
        text-align: center;
    }

    .container p{
        font-size: 22px;
        line-height: 27px;
        text-align: center;
    }

    .useCasesTitle{
        justify-content: center;
    }

    .useCases {
        justify-content: center;
    }

    .useCases span{
        font-size: 22px;
        line-height: 25px;
    }

    .functional{
        flex-direction: column;
    }

    .outputVoice{
        margin-left: 0;
    }

    .inputText img{
        width: 44px;
    }

    .textContainer span:first-child{
        font-size: 22px;
        line-height: 22px
    }

    .textContainer span:last-child{
        font-size: 22px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.5);
    }
}
